import * as React from 'react';
import styled, { css } from 'styled-components';
import { slideInRow, removeHighlight } from 'styles/animations';
import { SCROLLER_WIDTH } from 'styles/GlobalStyle';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';

interface OptionalClassName {
  className?: string;
}

const RIGHT_PAD = { paddingRight: `${SCROLLER_WIDTH}px` };

export const IconSortDesc = IoIosArrowRoundDown;
export const IconSortAsc = IoIosArrowRoundUp;

export const StyledTableWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  grid-template-rows: auto 1fr;
`;
export const TableWrapper: React.FC<
  React.PropsWithChildren<OptionalClassName & { isActive?: boolean }>
> = ({ children, className = '', isActive = true }) => {
  const display = isActive ? 'grid' : 'hidden';
  return (
    <StyledTableWrapper
      className={`${display} h-full flex-auto flex-col overflow-x-auto rounded ${className}`}
    >
      {children}
    </StyledTableWrapper>
  );
};

interface TableRowProps extends OptionalClassName {
  tableBodyRow?: boolean;
  removePad?: boolean;
  isAnimated?: boolean;
}
const AnimatedBackground = styled.div`
  background-color: ${({ theme }) => theme.color.buttonPrimary};
  animation: ${(props) => removeHighlight(props)} 1ms linear 800ms 1 normal forwards;
`;

// TODO: REMOVE THIS?
// TODO: REMOVE THIS?
// TODO: REMOVE THIS?
// TODO: REMOVE THIS?
// TODO: REMOVE THIS?
// TODO: REMOVE THIS?
const RowBackground = styled.div`
  content-visibility: auto;
  contain-intrinsic-size: 36px;
`;
export const AnimatedRowContainer = styled(RowBackground)<TableRowProps>`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  animation: ${slideInRow} 500ms linear 200ms 1 normal forwards;
  opacity: 0;
`;
export const RowContainer = styled.div<TableRowProps>``;
const StyleTableRow = styled.div<TableRowProps>`
  border-bottom: ${({ tableBodyRow, theme }) =>
    tableBodyRow ? `1px solid ${theme.color.border}` : 'none'};
  display: grid;
  align-items: ${({ tableBodyRow }) => (tableBodyRow ? 'center' : 'end')};
`;
export const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = (props) => {
  const pad = props.removePad ? '' : 'px-4';
  const headerStyles = props.removePad ? RIGHT_PAD : {};
  const Background = props.isAnimated ? AnimatedBackground : RowBackground;
  const Container = props.isAnimated ? AnimatedRowContainer : RowContainer;
  return (
    <Background>
      <Container {...props} className={`${props.className || ''} ${pad}`} style={headerStyles}>
        <StyleTableRow {...props}>{props.children}</StyleTableRow>
      </Container>
    </Background>
  );
};

interface TableCellProps {
  removePad?: boolean;
}

const tableCellStyles = css`
  line-height: 1.4;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  display: flex;
`;

export const StyledTableCell = styled.div`
  ${tableCellStyles};
  font-size: 0.7rem;
`;

export const StyledTableCellSmall = styled.div`
  ${tableCellStyles};
  font-size: 0.6rem;
`;

export const TableCell: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className = '',
}) => {
  return <StyledTableCell className={`pr-3 ${className}`}>{children}</StyledTableCell>;
};

export const TableCellSmall: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <StyledTableCellSmall className="pr-3">{children}</StyledTableCellSmall>;
};

export const TableCellNoPad: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <StyledTableCell>{children}</StyledTableCell>;
};

interface HeaderCellWrapperProps extends TableCellProps {
  isUnusual?: boolean;
  isSweep?: boolean;
  isActiveSort?: boolean;
  onClick?: () => void;
}

const StyledHeaderCellWrapper = styled(StyledTableCell)<HeaderCellWrapperProps>`
  cursor: pointer;
  user-select: none;
  margin-right: ${({ isActiveSort }) => (isActiveSort ? '4px' : 0)};
  ${({ theme, isUnusual, isSweep, isActiveSort }) => {
    if (isActiveSort) {
      return `border-bottom: 1px solid ${theme.color.graphCall};`;
    } else if (isUnusual) {
      return `border-bottom: 1px solid ${theme.color.unusualTrade};`;
    } else if (isSweep) {
      return `border-bottom: 1px solid ${theme.color.graphSweep};`;
    } else {
      return 'border-bottom: 1px solid transparent;';
    }
  }}

  .cell-name {
    overflow: ${({ isActiveSort }) => (isActiveSort ? 'hidden' : 'visiable')};
    text-overflow: ${({ isActiveSort }) => (isActiveSort ? 'ellipsis' : 'visiable')};
  }
`;

export const HeaderCellWrapper: React.FC<React.PropsWithChildren<HeaderCellWrapperProps>> = ({
  children,
  removePad,
  isUnusual,
  isSweep,
  isActiveSort,
  onClick,
}) => {
  return (
    <StyledHeaderCellWrapper
      onClick={onClick}
      className={`${!removePad && !isActiveSort ? 'pr-3' : ''} pb-3`}
      isUnusual={isUnusual}
      isSweep={isSweep}
      isActiveSort={isActiveSort}
    >
      {children}
    </StyledHeaderCellWrapper>
  );
};

export const StyledTableHeader = styled.div<{ isDark?: boolean }>`
  background-color: ${({ theme, isDark }) =>
    isDark ? theme.color.backgroundSecondary : theme.color.paper};
`;
export const TableHeader: React.FC<
  React.PropsWithChildren<{ isDark?: boolean; style?: object } & OptionalClassName>
> = ({ children, isDark, className = '', style }) => {
  return (
    <StyledTableHeader
      isDark={isDark}
      className={`flex-grow-1 relative flex-shrink-0 p-4 pb-0 ${className}`}
      style={style}
    >
      {children}
    </StyledTableHeader>
  );
};

export const StyledTableBody = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
`;
export const TableBody: React.FC<
  React.PropsWithChildren<{ animateRows?: boolean; className?: string }>
> = ({ children, animateRows = false, className }) => {
  const paddingString = animateRows ? '' : 'pb-4 pt-1 ';
  return (
    <StyledTableBody
      className={`${paddingString}${
        className ? `${className} ` : ''
      }flex-shrink flex-grow overflow-auto`}
    >
      {children}
    </StyledTableBody>
  );
};

export const StyledHeaderCell = styled.div`
  font-size: 0.5rem;
  color: ${({ theme }) => theme.color.labelText};
`;
export const HeaderCell: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <StyledHeaderCell className="flex w-full items-end justify-between font-semibold uppercase">
      {children}
    </StyledHeaderCell>
  );
};

export const HeaderIcon = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.color.graphCall};
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  align-items: center;
`;

export const CallPutBackground = styled.div<{ type: string }>`
  background: ${({ type, theme }) =>
    type === 'Call' ? theme.color.callBackground : theme.color.putBackground};
  padding: 6px 2px;
  min-width: 46px;
  text-align: center;
  border-radius: 3px;
  font-weight: 700;
  line-height: 1;
  font-size: 0.65rem;
`;

export const CallPutBackgroundMobile = styled.div<{ type: string }>`
  background: ${({ type, theme }) =>
    type === 'Call' ? theme.color.callBackground : theme.color.putBackground};
  padding: 4px 2px;
  min-width: 46px;
  text-align: center;
  border-radius: 3px;
  font-weight: 700;
  line-height: 1;
  font-size: 0.75rem;
`;

export const CallPutText = styled.div<{ type: string }>`
  color: ${({ type, theme }) => (type === 'Call' ? theme.color.callText : theme.color.putText)};
`;

// TODO: Calculate color based on put flow %
export const SentimentText = styled.div<{ type: string }>`
  color: ${({ type, theme }) => (type === 'Call' ? theme.color.callText : theme.color.putText)};
`;

export const TopPositionText = styled.div<{ isTopPosition?: boolean }>`
  color: ${({ isTopPosition, theme }) =>
    isTopPosition ? theme.color.topPosition : theme.color.textPrimary};
`;

export const MutedText = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: 85%;
`;

export const WatchListIcon = styled.div`
  top: 1px;
  left: -6px;
  font-size: 10px;
`;
