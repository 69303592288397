export default function LogoSquare() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.67 412.21">
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.cls-1{fill:#fff;}.cls-2{fill:#73c19b;}.cls-3{fill:#22bd69;}.cls-4{fill:#0f8947;}.cls-5{fill:#5dd098;}.cls-6{fill:#46bc80;}.cls-7{fill:#2fa769;}.cls-8{fill:#199450;}',
          }}
        />
      </defs>
      <title>Logo</title>
      <g id="Calque_2">
        <g id="Calque_1-2">
          <path
            className="cls-1"
            d="M0,402.63v9.58a188.55,188.55,0,0,1,51.55-59.06v-9.58A188.44,188.44,0,0,0,0,402.63Z"
          />
          <path
            className="cls-2"
            d="M78.71,326.76v9.59a190,190,0,0,1,71.37-19.81V307A190.15,190.15,0,0,0,78.71,326.76Z"
          />
          <path
            className="cls-2"
            d="M179.09,307v9.58a190,190,0,0,1,70,19.15v-9.58A189.77,189.77,0,0,0,179.09,307Z"
          />
          <path
            className="cls-2"
            d="M277,343.08v9.59A187.1,187.1,0,0,1,323.67,403v-9.58A187.29,187.29,0,0,0,277,343.08Z"
          />
          <path
            className="cls-2"
            d="M0,394.68v7.95a188.44,188.44,0,0,1,51.55-59.06v-7.95A188.44,188.44,0,0,0,0,394.68Z"
          />
          <path
            className="cls-3"
            d="M78.71,326.76A190.15,190.15,0,0,1,150.08,307V299a190,190,0,0,0-71.37,19.81Z"
          />
          <path
            className="cls-3"
            d="M179.09,307a189.77,189.77,0,0,1,70,19.15v-7.95a189.77,189.77,0,0,0-70-19.15Z"
          />
          <path
            className="cls-3"
            d="M277,335.14v7.94a187.29,187.29,0,0,1,46.68,50.37V385.5A187.1,187.1,0,0,0,277,335.14Z"
          />
          <path
            className="cls-4"
            d="M51.55,318.39V335.6A188.81,188.81,0,0,0,0,394.65V377.44A188,188,0,0,1,51.55,318.39Z"
          />
          <path
            className="cls-4"
            d="M150.08,281.77v17.28a189.52,189.52,0,0,0-71.4,19.77v-17.2A189.45,189.45,0,0,1,150.08,281.77Z"
          />
          <path
            className="cls-4"
            d="M249.11,301v17.21a189.12,189.12,0,0,0-70.05-19.13V281.77A190.57,190.57,0,0,1,249.11,301Z"
          />
          <path
            className="cls-4"
            d="M323.64,368.3v17.21A187.11,187.11,0,0,0,277,335.1V317.89A187.11,187.11,0,0,1,323.64,368.3Z"
          />
          <path
            className="cls-5"
            d="M51.55,206.51V318.39A188,188,0,0,0,0,377.44V326.18A600.38,600.38,0,0,1,51.55,206.51Z"
          />
          <path
            className="cls-6"
            d="M150.36,80.93v34.84q-.11,83-.28,166a189.45,189.45,0,0,0-71.4,19.85V163.46a509.49,509.49,0,0,1,71.4-82.25C150.15,81.14,150.29,81.07,150.36,80.93Z"
          />
          <path
            className="cls-7"
            d="M179.06,56.72c-.07.07-.21.15-.28.22l.28,224.83A190.57,190.57,0,0,1,249.11,301V14.38C227.33,23.81,203.48,37.87,179.06,56.72Z"
          />
          <path
            className="cls-8"
            d="M323.64,1.82V368.3A187.11,187.11,0,0,0,277,317.89V4.6C295.44-.39,311.43-1.32,323.64,1.82Z"
          />
        </g>
      </g>
    </svg>
  );
}
