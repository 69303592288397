import * as React from 'react';
import Link from 'components/Link';
import { useRouter } from 'next/router';
import { FaUser } from 'react-icons/fa';
import useViewer from 'hooks/useViewer';
import { FiLogOut } from 'react-icons/fi';
import { BsArrowUpCircle } from 'react-icons/bs';
import SkipToContent from 'layouts/SkipToContent';
import Navbar from 'components/Navbar';
import {
  hasDashboardAccess,
  // hasAlertAccess,
} from 'services/client/token';
import Footer from 'layouts/Footer';
import SidebarLabel from 'components/SidebarLabel';
import {
  SETTINGS_PAGE,
  LOGOUT_PAGE,
  SIDEBAR_TOP_ITEMS,
  OPTION_FLOW_PAGE,
  PRICING_PAGE,
  SIGNUP_PAGE,
  HOME_PAGE,
} from 'constants/pages';
import { AuthStatus } from 'constants/auth';
import LogoSquare from 'svg/logo-square';
import { trackEvent } from 'services/client/analytics';
import { CLICK_UPGRADE } from 'services/client/analytics/events';
import classNames from 'styles/utils/classNames';
import { Props } from './props';

import {
  Page,
  PageContent,
  PageWrapper,
  Sidebar,
  SidebarItem,
  SidebarItemAlert,
  IconBackground,
} from './styles';

export default function PageLayoutDefault({
  children,
  hideSkipToContent,
  hideNav,
  subNav,
  hideFooter,
  isPreventScroll,
}: React.PropsWithChildren<Props>) {
  const router = useRouter();
  const viewer = useViewer();
  const isLoading = viewer.status === AuthStatus.Loading;
  const isDashboardUser = hasDashboardAccess(viewer);
  // const isAlertUser = hasAlertAccess(viewer);

  return (
    <Page className="flex min-h-screen w-screen min-w-full flex-col overflow-x-hidden">
      {!hideSkipToContent && <SkipToContent />}
      {!hideNav && <Navbar />}
      {subNav}
      <PageWrapper className="flex max-h-screen max-w-full flex-grow overflow-hidden">
        <Sidebar className="safearea-pad-top hidden flex-shrink-0 flex-col items-center justify-between sm:flex">
          <div className="w-full">
            <Link href={isLoading || isDashboardUser ? OPTION_FLOW_PAGE : HOME_PAGE}>
              <SidebarItem className="mb-1 p-4">
                <LogoSquare />
              </SidebarItem>
            </Link>
            {SIDEBAR_TOP_ITEMS.map(({ href, Icon, name }) => {
              const isActive = href === router.pathname;

              return (
                <div className="mb-2" key={href}>
                  <Link key={href} href={href}>
                    <SidebarLabel name={name}>
                      <SidebarItem key={href} className="relative p-4" isActive={isActive}>
                        <Icon className="h-full w-full fill-current" />
                      </SidebarItem>
                    </SidebarLabel>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="w-full">
            {process.env.IS_MEMBER_SALE !== 'true' && isDashboardUser && (
              <Link
                href={`${SETTINGS_PAGE}?upgrade=true`}
                onClick={() =>
                  trackEvent(CLICK_UPGRADE, {
                    type: 'upgrade',
                  })
                }
              >
                <SidebarItem className="px-3 py-2">
                  <BsArrowUpCircle className="h-full w-full text-green-200" />
                </SidebarItem>
              </Link>
            )}
            {!isDashboardUser && (
              <Link
                href={PRICING_PAGE}
                onClick={() =>
                  trackEvent(CLICK_UPGRADE, {
                    type: 'pricing',
                  })
                }
              >
                <SidebarItem className="px-3 py-2">
                  <BsArrowUpCircle className="h-full w-full text-green-200" />
                </SidebarItem>
              </Link>
            )}
            {!!viewer?.viewer?.uid && (
              <Link href={LOGOUT_PAGE}>
                <SidebarItemAlert className="px-4 py-2">
                  <FiLogOut className="fill h-full w-full" />
                </SidebarItemAlert>
              </Link>
            )}
            <Link href={!!viewer?.viewer?.uid ? SETTINGS_PAGE : SIGNUP_PAGE}>
              <SidebarItem className="p-2 py-3">
                <IconBackground className="rounded-full p-3">
                  <FaUser className="h-full w-full fill-current" />
                </IconBackground>
              </SidebarItem>
            </Link>
          </div>
        </Sidebar>
        <PageContent
          id="main"
          className={classNames(
            'block h-screen w-full flex-grow',
            isPreventScroll ? 'overflow-y-hidden' : 'overflow-y-auto',
          )}
          tabIndex={-1}
        >
          {children}
        </PageContent>
      </PageWrapper>
      {!hideFooter && <Footer />}
    </Page>
  );
}
